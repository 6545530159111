import { AxiosInstance } from 'axios';
import { CustomException } from '@core/modules/common/infra/error';
import { formatQueryParams } from '@core/modules/common/infra/http';
import { httpMessage } from '@core/modules/common/infra/message';
import {
    Schedule,
    TGet,
    SchedulePagination,
    ScheduleExport,
    TAll,
    TProcedureUpdate,
} from '../domain/schedule.entities';
import { ScheduleGateway } from '../domain/schedule.gateway';
import { Paths } from './keys';

export class ScheduleHttpGateway implements ScheduleGateway {
    constructor(private http: AxiosInstance) { }

    async get(params: TGet): Promise<Schedule> {
        return this.http
            .get(`${Paths.default}/${params.id}`)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('agendamento'));
                }
                return new Schedule(res.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o agendamento'));
            });
    }

    async getAll(params: TAll): Promise<SchedulePagination> {
        return this.http
            .get(formatQueryParams(Paths.default, params))
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('agendamentos'));
                }

                return new SchedulePagination(res.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('os agendamentos'));
            });
    }

    async export(): Promise<ScheduleExport> {
        return this.http
            .get(`${Paths.default}/export`)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('agendamentos'));
                }
                return new ScheduleExport({
                    data: res.data,
                });
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('os agendamentos'));
            });
    }

    async update(params: TProcedureUpdate): Promise<boolean> {
        return this.http
            .put(Paths.default, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.updateResponseEmpty('update scheduleValue'));
                }
                return true
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.updateError('update scheduleValue'));
            });
    }

    async confirm(ids: string[]): Promise<boolean> {
        return this.http.put(Paths.v1.confirm, {
            schedules: ids
        }).then(() => {
            return true
        }).catch((error) => {
            throw new Error(error)
        })
    }

    async cancel(id: string, obs: string): Promise<boolean> {
        return this.http.put(Paths.v1.cancel.replace(':id', id), {
            obs: obs
        }).then(() => true).catch((error) => {
            throw new Error(error)
        })
    }

    async resendBilling(email: string, scheduleId: string): Promise<void> {
        return this.http.post(Paths.v1.resendBilling.replace(':id_schedule', scheduleId), {
            email: email
        }).then(() => { }).catch((error) => {
            throw new Error(error)
        })
    }
}
