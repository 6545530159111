import React from 'react';
import { Layout as AntLayout, theme, Drawer } from 'antd';
import style from './style';

import Menu from './menu';
import Profile from '../profile';
import IconClose from './iconClose';
import Footer from './footer';
import { useSelectedMenuContext } from '@/@presentation/contexts/selectedMenu';

interface ISidebar {
    defaultSelectedKeys: string;
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSmall: React.Dispatch<React.SetStateAction<boolean>>;
    isSmall: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}

const Sidebar: React.FC<ISidebar> = (props: ISidebar) => {
    const {
        token: {
            colorPrimaryBg,
        },
    } = theme.useToken();

    const onChangeIsSmall = (value: boolean) => {
        props.setIsSmall(value);
        if (value) {
            props.setOpen(false);
        }
    };

    const Content = () => {
        return (
            <div style={{
                backgroundColor: colorPrimaryBg,
            }}>
                <IconClose isSmall={props.isSmall} setOpen={props.setOpen} />
                <Profile />
                <Menu
                    isSmall={props.isSmall}
                    collapsed={props.collapsed}
                />
            </div>
        );
    };

    return (
        <div>
            <Drawer
                data-testid="sidebar-drawer"
                placement="left"
                closable={false}
                onClose={() => {
                    props.setOpen(!props.open);
                }}
                width={320}
                open={props.open && props.isSmall}
                style={style.drawer(colorPrimaryBg)}
                key="left"
            >
                <Content />
            </Drawer>

            <AntLayout.Sider
                data-testid="sidebar-layout-sider"
                collapsible
                breakpoint="lg"
                collapsed={props.collapsed}
                onCollapse={(value) => props.setCollapsed(value)}
                trigger={null}
                onBreakpoint={(broken) => onChangeIsSmall(broken)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    boxShadow: '7px 3px 12px 0px rgba(0, 0, 0, 0.05)',
                    ...style.sidebar(colorPrimaryBg),
                }}
                hidden={props.isSmall}
                width={256}
            >
                <Content />
                <Footer collapsed={props.collapsed} setCollapsed={props.setCollapsed} />
            </AntLayout.Sider>
        </div>
    );
};

export default Sidebar;
