import React from 'react';
import {Form, Layout, Popover, Typography, Space, Avatar, Divider} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';
import {
    List,
    DataType,
    getFieldsForFilter,
    getPageAndPerPage,
    ResetPassword,
} from '@presentation/components/form';
import {formattedRoute, routes} from '../../routes';
import {Notification} from '@presentation/components/common';
import surgeon from '@core/modules/surgeon/infra/container.registry';
import {Surgeon} from '@core/modules/surgeon/domain/surgeon.entities';
import word from '@core/modules/common/infra/word';
const {Link} = Typography;

const SurgeonList = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<Surgeon>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const options = {
        value: 'search',
        name: 'Nome',
        placeholder: 'Pesquisar por cirurgião...',
    };

    async function loadData(params?: any) {
        return await surgeon.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });
    }

    const changeResetPassword = async (email?: string) => {
        if (!email) {
            Notification.error({
                message: 'Cirurgião não possui e-mail para envio do código!',
            });
            return;
        }
        setEmail(email);
        setVisible(!visible);
    };

    const createAccess = (record: Surgeon) => {
        if (!record?.person?.email || !record?.id) {
            Notification.error({
                message: 'Cirurgião não possui e-mail para criar acessos!',
            });
            return;
        }
    
        setLoading(true);
    
        surgeon.createAccess.execute({ id: record.id })
            .then(() => {
                Notification.success({ message: 'Acessos criados com sucesso!' });
            })
            .catch((error: any) => {
                Notification.error({
                    message: error.message,
                });
            })
            .finally(() => {
                setLoading(false);
                setReload(true);
            });
    };    

    const content = (record: Surgeon) => {
        if (!record.customerPortalUserId) {
            return (
                <Space direction="vertical">
                    <Link
                        style={{color: '#A1A2A2'}}
                        onClick={() => createAccess(record)}
                        target="_blank"
                    >
                        Criar acessos
                    </Link>
                </Space>
            );
        }
        return (
            <Space direction="vertical">
                <Link
                    style={{color: '#A1A2A2'}}
                    onClick={() => navigate(formattedRoute(routes.SURGEON_EDIT, record.id))}
                    target="_blank"
                >
                    Editar
                </Link>
                <Divider style={{margin: 0, padding: 0}} />
                <Link
                    style={{color: '#A1A2A2'}}
                    onClick={() => changeResetPassword(record?.person?.email)}
                    target="_blank"
                >
                    Recuperar senha
                </Link>
            </Space>
        );
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome ',
            sortDirections: ['descend'],
            fixed: 'left',
            width: '40%',
            render: (record) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Popover
                        content={content(record)}
                        placement="bottom"
                        trigger="click"
                        overlayStyle={{
                            border: '1.5px solid #D9D9D9',
                            borderRadius: 13,
                            backdropFilter: 'blur(2px)',
                        }}
                    >
                        <img src="/menu-points.svg" alt="Points" style={{height: 16, width: 12}} />
                    </Popover>
                    <Avatar size={38} shape="square">
                        {word.defaultName(word.getFirstTwoLetters(record?.person?.name ?? ''))}
                    </Avatar>
                    <>{record?.person?.name}</>
                </div>
            ),
        },
        {
            title: 'Email ',
            dataIndex: 'person',
            render: (record) => <>{record.email}</>,
            defaultSortOrder: 'descend',
            width: '30%',
            responsive: ['sm'],
        },
        {
            title: 'Telefone',
            dataIndex: 'person',
            render: (record) => <>{record.phone}</>,
            defaultSortOrder: 'descend',
            width: '20%',
            responsive: ['sm'],
        },
    ];

    return (
        <Layout data-testid="layout-administration">
            {visible && <ResetPassword email={email} setVisible={setVisible} visible={visible} />}
            <List
                columns={columns}
                loadData={loadData}
                Form={Form}
                setLoading={setLoading}
                loading={loading}
                options={options}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setParams={setParams}
                params={params}
                setReload={setReload}
                isReload={isReload}
                hideDataFilter={true}
            />
        </Layout>
    );
};

export default SurgeonList;
