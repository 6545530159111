import { IConciliator } from "@/@core/modules/conciliator/domain/conciliator.entity";
import { ColumnsType } from "antd/es/table";

export const columns = (navigate: any): ColumnsType<IConciliator> => [
    {
        title: 'ID Conciliação',
        dataIndex: 'idConciliation',
        sortDirections: ['descend'],
        fixed: 'left',
        responsive: ['sm'],
    },
    {
        title: 'ID da Agenda',
        dataIndex: 'schedules',
        render: (value: number) => {
            if (value) {
                return <a onClick={() => navigate(`/schedule/${value}`)}>{value}</a>
            } else {
                return 'ID Não Encontrado';
            }
        },
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Nro. Primário Pedido',
        dataIndex: 'primaryOrderNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Nro. Primário NF',
        dataIndex: 'primaryInvoiceNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Data Aprovação',
        dataIndex: 'approvalDate',
        render: (value: string) => new Date(value).toLocaleDateString(),
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Valor Transação',
        dataIndex: 'transactionValue',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
        render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value),
    },
    {
        title: 'Taxa aplicada',
        dataIndex: 'appliedFee',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
        render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value),
    },
    {
        title: 'Valor Líquido',
        dataIndex: 'liquidValue',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
        render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value),
    },
    {
        title: 'Nro. Parc.',
        dataIndex: 'installmentNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Chave VINDI',
        dataIndex: 'vindiKey',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Tipo Pgto',
        dataIndex: 'paymentType',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Obs.',
        dataIndex: 'observation',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    }
];
