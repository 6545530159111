import {useEffect, useState, useCallback} from 'react';
import {Select} from 'antd';
import surgeon from '@core/modules/surgeon/infra/container.registry';
import {SelectValue} from 'antd/es/select';

const defaultPerPage = 100;
interface IPagination {
    page: number;
    perPage: number;
    total: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

interface ISelectSurgeon {
    defaultValue?: any[];
    setSurgeons: React.Dispatch<any>;
}

const SelectSurgeon = (props: ISelectSurgeon) => {
    const [value, setValue] = useState<any>(props.defaultValue ?? []);
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<IPagination>({
        page: 0,
        perPage: defaultPerPage,
        total: defaultPerPage,
        pageCount: 1,
        hasPreviousPage: true,
        hasNextPage: true,
    });

    const initialize = useCallback(async () => {
        setLoading(true);
        setData([]);

        try {
            const surgeonAll = await surgeon.getAll?.execute({
                offset: pagination.page !== 0 ? (pagination.page - 1) * pagination.perPage : 0,
                limit: 1000,
            });
            setPagination(surgeonAll.pagination);

            if (surgeonAll?.data) {
                setData(surgeonAll.data);
            }
        } finally {
            setLoading(false);
        }
    }, [props.defaultValue]);

    const fetchMoreData = useCallback(async () => {
        if (loading) return;
        if (!pagination?.hasNextPage && pagination.page > 1) return;
        setLoading(true);
        try {
            const result = await surgeon.getAll?.execute({
                offset: (pagination.page + 1 - 1) * pagination.perPage,
                limit: defaultPerPage,
            });
            if (result?.data) {
                setPagination(result.pagination);
                setData((prevData: any) => [...prevData, ...result.data]);
            }
        } finally {
            setLoading(false);
        }
    }, [loading, pagination]);

    const handleSearch = async (value: string) => {
        if (!value || value.length < 3) return;
        setLoading(true);
        try {
            const result = await surgeon.getAll?.execute({
                offset: pagination.page !== 0 ? (pagination.page - 1) * pagination.perPage : 0,
                limit: 10,
                search: value,
            });
            if (!result?.data) return;
            setPagination(result.pagination);
            setData(result.data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        initialize();
    }, [initialize]);

    useEffect(() => {
        if (data.length > 0) {
            setValue(props.defaultValue);
            props.setSurgeons(props.defaultValue);
        }
    }, data);

    const onChange = (value: SelectValue) => {
        setValue(value);
        props.setSurgeons(value);
    };

    return (
        <Select
            onPopupScroll={(e) => {
                const container = e.target as HTMLDivElement;
                const isCloseToBottom =
                    container.scrollHeight - container.scrollTop === container.clientHeight;

                if (!loading && isCloseToBottom) {
                    fetchMoreData();
                }
            }}
            style={{
                minWidth: '78%',
                maxWidth: '78%',
                height: value?.length <= 1 ? '40px' : '100%',
            }}
            mode="multiple"
            showSearch
            value={value}
            placeholder={'Procurar...'}
            data-testid={`select-surgeon`}
            filterOption={false}
            defaultValue={props.defaultValue}
            onSearch={handleSearch}
            onChange={onChange}
        >
            {data?.map((item: any) => (
                <Select.Option
                    selected={props.defaultValue?.includes(item.id?.toString())}
                    key={item.id?.toString()}
                    value={item.id?.toString()}
                    label={item?.person?.name}
                >
                    {item?.person?.name}
                </Select.Option>
            ))}
        </Select>
    );
};

export {SelectSurgeon};
