export const keys = {
  unit: {
    createUnit: '/customer-portal/units/unit/',
    getUnit: '/customer-portal/units/unit/',
    getUnitById: '/customer-portal/units/unit/:id',
    deleteUnit: '/customer-portal/units/unit/:id',
  },
  surgeon: {
    createSurgeon: '/customer-portal/units/surgeons',
    getSurgeon: '/customer-portal/units/surgeons',
    deleteSurgeon: '/customer-portal/units/surgeons',
  },
  secretary: {
    createSecretary: '/customer-portal/units/secretaries',
    getSecretary: '/customer-portal/units/secretaries',
    deleteSecretary: '/customer-portal/units/secretaries',
  },
}