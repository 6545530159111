import {TPagination} from '@core/modules/common/domain/common.entities';
import money from '@core/modules/common/infra/money';
import date from '@core/modules/common/infra/date';

export type TAll = {
    offset: number;
    limit: number;
    id?: string;
    name?: string;
    active?: boolean;
    dtIni?: string
    dtFim?: string
    schedule_id?: number
    search?: string

    status?: string;
    patient?: string;
    requester?: string;
    surgeon_ids?: string;
    unity_ids?: string;
};

export type TGet = {
    id: string;
};

type TProcedure = {
    id: number;
    tussCode: string;
    name: string;
    amountFeesTable: number;
    amountSpecialHourFeesTable: number;
};

export type TDataItem = {
    id: string;
    scheduleDate: Date;
    procedureDate: Date;
    timeProcedure: Date;
    unit: string;
    surgeon: string;
    receiverName: string;
    payerName?: string,
    procedure: string;
    procedures: TProcedure[];
    value: string;
    total: string;
    anesthetist: string;
    patient: string;
    status: string;
    amountPaid: string;
    amountFeesTable: string;
    amountSpecialHourFeesTable: string;
    surgeonUseFeesTable: boolean;
    taxNoteNumber: number;
    lastSendedEmailDate: Date;
    lastSendedEmail: string;
    lastSendedEmailPaymentLink: string;
    lastSendedEmailPaymentType: string;
    canEdit: boolean
};

export type TDataItemExport = {
    id: string;
    scheduleDate: Date;
    procedureDate: Date;
    timeProcedure: Date;
    unit: string;
    surgeon: string;
    receiverName: string;
    procedure: string;
    procedures: TProcedureExport[];
    value: number;
    total: number;
    anesthetist: string;
    patient: string;
    status: string;
    amountPaid: string;
    amountFeesTable: string;
    amountSpecialHourFeesTable: string;
    surgeonUseFeesTable: boolean;
    taxNoteNumber: number;
};

export type TProcedureExport = {
    name: string;
    code: string;
    tuss: string;
};

export type TProcedureUpdate = {
    id: number
    amount: number
    netValue?: number
    paymentCustomerId?: number
    updatedBySecretary: boolean
};

enum STATUS {
    CANCELED = 'canceled',
    RESCHEDULED = 'rescheduled',
    FINISHED = 'finished',
    CONFIRMED = 'confirmed',
    CONFIRMED_BACKOFFICE = 'confirmed_backoffice',
    CONFIRMED_SURGEON = 'confirmed_surgeon',
    IN_PROGRESS = 'inProgress',
    PENDING = 'pending',
}

export const formatStatus = (value: string): string => {
    switch (value) {
        case STATUS.PENDING:
            return 'PENDENTE';
        case STATUS.CANCELED:
            return 'CANCELADO';
        case STATUS.FINISHED:
            return 'FINALIZADO';
        case STATUS.CONFIRMED:
            return 'CONFIRMADO';
        case STATUS.CONFIRMED_BACKOFFICE:
            return 'CONFIRMADO PELO BACKOFFICE';
        case STATUS.CONFIRMED_SURGEON:
            return 'CONFIRMADO PELO CIRURGIÃO';
        case STATUS.IN_PROGRESS:
            return 'EM ANDAMENTO';
        case STATUS.RESCHEDULED:
            return 'REAGENDADO';
        default:
            return 'STATUS DESCONHECIDO';
    }
};

export const formatTaxNoteNumber = (value: number) => {
    if (value === 0) return undefined;
    return value;
};

export class SchedulePagination {
    constructor(
        public props: {
            data: TDataItem[];
            pagination?: TPagination;
        },
    ) {}

    get data() {
        return this.props.data.map((item) => ({
            ...item,
            value: money.formatCurrency(item?.value),
            total: money.formatCurrency(item?.total),
            amountPaid: money.formatCurrency(item?.amountPaid),
            amountFeesTable: money.formatCurrency(item?.amountFeesTable),
            amountSpecialHourFeesTable: money.formatCurrency(item?.amountSpecialHourFeesTable),
            procedureDate: date.formatCurrency(item?.procedureDate),
            procedureHour: date.formatCurrencyWithHHmmSS(item?.procedureDate),
            scheduleDate: date.formatCurrency(item?.scheduleDate),
            status: formatStatus(item?.status),
            taxNoteNumber: formatTaxNoteNumber(item?.taxNoteNumber),
        }));
    }
    get pagination() {
        return this?.props?.pagination;
    }

    toJSON() {
        return {
            data: this.data,
            pagination: this?.pagination,
        };
    }
}

export class Schedule {
    constructor(public props: TDataItem) {}

    get id() {
        return this.props.id;
    }
    get scheduleDate() {
        return this?.props.scheduleDate
            ? date.formatCurrencyWithHHmmSS(this?.props.scheduleDate)
            : undefined;
    }
    get procedureDate() {
        return this?.props.procedureDate
            ? date.formatCurrencyWithHHmmSS(this?.props.procedureDate)
            : undefined;
    }
    get timeProcedure() {
        return this.props.timeProcedure;
    }
    get unit() {
        return this.props.unit;
    }
    get surgeon() {
        return this.props.surgeon;
    }
    get receiverName() {
        return this.props.receiverName;
    }
    get payerName() {
        return this?.props?.payerName;
    }
    get procedure() {
        return this.props.procedure;
    }
    get procedures() {
        return this.props.procedures;
    }
    get value() {
        return money.formatCurrency(this?.props?.value);
    }
    get total() {
        return money.formatCurrency(this?.props?.total);
    }
    get anesthetist() {
        return this.props.anesthetist;
    }
    get patient() {
        return this.props.patient;
    }
    get status() {
        return formatStatus(this.props.status);
    }
    get amountPaid() {
        return money.formatCurrency(this.props.amountPaid);
    }
    get amountFeesTable() {
        return money.formatCurrency(this.props.amountFeesTable);
    }
    get amountSpecialHourFeesTable() {
        return money.formatCurrency(this.props.amountSpecialHourFeesTable);
    }
    get surgeonUseFeesTable() {
        return this.props.surgeonUseFeesTable;
    }
    get taxNoteNumber() {
        return formatTaxNoteNumber(this.props.taxNoteNumber);
    }

    get lastSendedEmailDate() {
        return this?.props.lastSendedEmailDate
            ? date.formatCurrencyWithHHmmSS(this?.props.scheduleDate)
            : undefined;
    }

    get lastSendedEmail() {
        return this?.props.lastSendedEmail
    }

    get lastSendedEmailPaymentLink() {
        return this?.props.lastSendedEmailPaymentLink
    }

    get lastSendedEmailPaymentType() {
        return this?.props.lastSendedEmailPaymentType
    }

    get canEdit() {
        return this.props.canEdit;
    }



    toJSON() {
        return {
            id: this.id,
            scheduleDate: this?.scheduleDate,
            procedureDate: this?.procedureDate,
            timeProcedure: this.timeProcedure,
            unit: this.unit,
            surgeon: this.surgeon,
            anesthesia: this.receiverName,
            procedure: this.procedure,
            procedures: this.procedures,
            value: this.value,
            total: this.total,
            anesthetist: this.anesthetist,
            patient: this.patient,
            status: this.status,
            amountPaid: this.amountPaid,
            amountFeesTable: this.amountFeesTable,
            amountSpecialHourFeesTable: this.amountSpecialHourFeesTable,
            surgeonUseFeesTable: this.surgeonUseFeesTable,
            taxNoteNumber: this.taxNoteNumber,
            lastSendedEmailDate: this.lastSendedEmailDate,
            lastSendedEmail: this.lastSendedEmail,
            lastSendedEmailPaymentLink: this.lastSendedEmailPaymentLink,
            lastSendedEmailPaymentType: this.lastSendedEmailPaymentType,
            canEdit: this.canEdit,
        };
    }
}

export class ScheduleExport {
    constructor(
        public props: {
            data: TDataItemExport[];
        },
    ) {}

    get data() {
        return this.props.data;
    }

    toJSON() {
        return {
            data: this.data,
        };
    }
}