import { Container } from "inversify"
import { httpApi } from "../../common/infra/http"
import { UnitHttpGateway } from "./unit.http.gateway"
import { ListUnitUseCase } from "../application/list-unit.usecase"
import { CreateUnitUseCase } from "../application/create-unit.usecase"
import { GetUnitByIdUseCase } from "../application/get-unit-by-id.usecase"
import { DeleteUnitUseCase } from "../application/delete-unit.usecase"
import { CreateUnitSurgeonUseCase } from "../application/create-unit-surgeon.usecase"
import { ListUnitSurgeonUseCase } from "../application/list-unit-surgeon.usecase"
import { DeleteUnitSurgeonUseCase } from "../application/delete-unit-surgeon.usecase"
import { CreateUnitSecretaryUseCase } from "../application/create-unit-secretary.usecase"
import { ListUnitSecretaryUseCase } from "../application/list-unit-secretary.usecase"
import { DeleteUnitSecretaryUseCase } from "../application/delete-unit-secretary.usecase"

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    UnitGateway: Symbol.for('UnitGateway'),
    ListUnitUseCase: Symbol.for('ListUnitUseCase'),
    CreateUnitUseCase: Symbol.for('CreateUnitUseCase'),
    getUnitByIdUseCase: Symbol.for('GetUnitByIdUseCase'),
    deleteUnitUseCase: Symbol.for('DeleteUnitUseCase'),

    CreateUnitSurgeonUseCase: Symbol.for('CreateUnitSurgeonUseCase'),
    ListUnitSurgeonUseCase: Symbol.for('ListUnitSurgeonUseCase'),
    deleteUnitSurgeonUseCase: Symbol.for('DeleteUnitSurgeonUseCase'),

    CreateUnitSecretaryUseCase: Symbol.for('CreateUnitSecretaryUseCase'),
    ListUnitSecretaryUseCase: Symbol.for('ListUnitSecretaryUseCase'),
    DeleteUnitSecretaryUseCase: Symbol.for('DeleteUnitSecretaryUseCase'),
}

export const container = new Container()

// ########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi)

// ########## GATEWAYS
container.bind(Registry.UnitGateway).toDynamicValue((context) => {
    return new UnitHttpGateway(context.container.get(Registry.AxiosAdapter))
})

// ########## USE CASES
container.bind(Registry.ListUnitUseCase).toDynamicValue((context) => {
    return new ListUnitUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.CreateUnitUseCase).toDynamicValue((context) => {
    return new CreateUnitUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.getUnitByIdUseCase).toDynamicValue((context) => {
    return new GetUnitByIdUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.deleteUnitUseCase).toDynamicValue((context) => {
    return new DeleteUnitUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.CreateUnitSurgeonUseCase).toDynamicValue((context) => {
    return new CreateUnitSurgeonUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.ListUnitSurgeonUseCase).toDynamicValue((context) => {
    return new ListUnitSurgeonUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.deleteUnitSurgeonUseCase).toDynamicValue((context) => {
    return new DeleteUnitSurgeonUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.CreateUnitSecretaryUseCase).toDynamicValue((context) => {
    return new CreateUnitSecretaryUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.ListUnitSecretaryUseCase).toDynamicValue((context) => {
    return new ListUnitSecretaryUseCase(context.container.get(Registry.UnitGateway))
})

container.bind(Registry.DeleteUnitSecretaryUseCase).toDynamicValue((context) => {
    return new DeleteUnitSecretaryUseCase(context.container.get(Registry.UnitGateway))
})

// ######### CONTAINERS GET
const units = {
  unit: {
    list: container.get<ListUnitUseCase>(Registry.ListUnitUseCase),
    create: container.get<CreateUnitUseCase>(Registry.CreateUnitUseCase),
    get: container.get<GetUnitByIdUseCase>(Registry.getUnitByIdUseCase),
    delete: container.get<DeleteUnitUseCase>(Registry.deleteUnitUseCase),
  },
  surgeon: {
    create: container.get<CreateUnitSurgeonUseCase>(Registry.CreateUnitSurgeonUseCase),
    list: container.get<ListUnitSurgeonUseCase>(Registry.ListUnitSurgeonUseCase),
    delete: container.get<DeleteUnitSurgeonUseCase>(Registry.deleteUnitSurgeonUseCase),
  },
  secretary: {
    create: container.get<CreateUnitSecretaryUseCase>(Registry.CreateUnitSecretaryUseCase),
    list: container.get<ListUnitSecretaryUseCase>(Registry.ListUnitSecretaryUseCase),
    delete: container.get<DeleteUnitSecretaryUseCase>(Registry.DeleteUnitSecretaryUseCase),
  },
}

export default units
