import React from 'react';
import { Avatar, theme } from 'antd';
import { useAuthentication } from '@presentation/modules/authentication/contexts/authentication';
import word from '@core/modules/common/infra/word';
import style from './style';

const Profile: React.FC = () => {
    const { user } = useAuthentication();

    const translateUserType = (type) => {
        const types = {
            'BACKOFFICE': 'Backoffice',
            'SECRETARY': 'Secretária',
            'ANESTHETIST': 'Anestesista',
            'SURGEON': 'Cirurgião',
            'PATIENT': 'Paciente'
        };
        return types[type] || type;
    };

    const {
        token: { blue1, colorText },
    } = theme.useToken();

    return (
        <div style={style.container}>
            <Avatar style={style.avatar(blue1)} size={64} shape="square">
                {word.defaultName(word.getFirstTwoLetters(user?.name ?? ''))}
            </Avatar>
            <div style={style.containerInfo}>
                <span style={style.name}>
                    {word.defaultName(word.getFirstName(user?.name))}
                </span>
                <span style={style.type(colorText)}>{translateUserType(user?.type)}</span>
                <span style={style.email(colorText)}>{user?.email}</span>
            </div>
        </div>
    );
};

export default Profile;
